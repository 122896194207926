var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import 'rxjs/add/operator/toPromise';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import * as isArray from 'lodash/isArray';
import * as map from 'lodash/map';
import * as sum from 'lodash/sum';
import * as values from 'lodash/values';
import { Order } from '../../orders/shared';
import { BarcoderService } from '../../shared/services/barcoder.service';
import { ConfigService } from './config.service';
import { RollbarService } from './error-handler.service';
/**
 * Handles getting, finding, and creating of orders
 *
 * @export
 * @class OrderService
 */
var OrderService = /** @class */ (function () {
    function OrderService(http, config, injector, barcoderService) {
        var _this = this;
        this.http = http;
        this.config = config;
        this.barcoderService = barcoderService;
        this.handleError = function (error) {
            var posNumber = korona_plugin_api && korona_plugin_api.request && korona_plugin_api.request.pos.number;
            _this.rollbar.warn(__assign({}, error, { posNumber: posNumber }));
            if (error.body || error._body) {
                error.body = error.json();
                if (error.body.errors && !isArray(error.body.errors)) {
                    error.body.error = values(error.body['errors']).join('\n');
                    error.body.errors = null;
                }
            }
            if (error.error && error.error.errors) {
                if (error.error.errors && !isArray(error.error.errors)) {
                    error.body = { error: values(error.error.errors).join('\n') };
                }
            }
            else if (error.error) {
                error.body = error.error;
            }
            _this.orderInProgress = false;
            return Promise.reject(error);
        };
        this.extractData = function (res) {
            if (res.order) {
                res.order = _this.initOrder(res.order);
                _this.currentOrder = res.order;
            }
            else if (res.orders) {
                res.orders = _this.initOrders(res.orders);
            }
            _this.orderInProgress = false;
            return res || {};
        };
        this.rollbar = injector.get(RollbarService);
    }
    /**
     * Gets an array of orders from the back-end (go~mus)
     *
     * @returns {Promise<{orders: Order[], meta: Meta}>}
     *
     * @memberOf OrderService
     */
    OrderService.prototype.getOrders = function (config) {
        return this.http
            .get(this.config.get('apiBaseUrl') + '/orders', {
            withCredentials: true,
            params: { per_page: config.itemsPerPage.toString(), page: config.currentPage.toString() },
        })
            .toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    };
    /**
     * Gets an order from the back-end (go~mus)
     *
     * @param {string} order_id
     * @returns {Promise<{order: Order}>}
     *
     * @memberOf OrderService
     */
    OrderService.prototype.getOrder = function (order_id) {
        return this.http
            .get(this.config.get('apiBaseUrl') + '/orders/' + order_id, {
            withCredentials: true,
        })
            .toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    };
    OrderService.prototype.receiptNumberExists = function () {
        var orderId = this.getReceiptOrderNumber();
        return !!orderId.length;
    };
    OrderService.prototype.existingOrderUpdateable = function () {
        if (this.receiptNumberExists()) {
            return this.getOrder(this.getReceiptOrderNumber()).then(function (res) {
                return res.order.updateable_by_cash_point;
            });
        }
        else {
            return Promise.resolve(true);
        }
    };
    OrderService.prototype.getReceiptOrderNumber = function () {
        var orderNumberAction = typeof korona_plugin_api !== 'undefined' &&
            korona_plugin_api.response.actions.find(function (action) { return action.type === 'setReceiptOrderNumberAction'; });
        return (typeof korona_plugin_api !== 'undefined' && korona_plugin_api.request &&
            korona_plugin_api.request.receipt.orderNumber) || orderNumberAction && orderNumberAction.orderNumber || '';
    };
    OrderService.prototype.canAddToReceipt = function (orderId) {
        return !this.receiptNumberExists() || this.getReceiptOrderNumber() === orderId.toString() ? true : false;
    };
    OrderService.prototype.order = function (order) {
        var _this = this;
        this.orderInProgress = true;
        if (this.receiptNumberExists()) {
            return this.getOrder(this.getReceiptOrderNumber()).then(function (resp) {
                _this.currentOrder = resp.order;
                if (_this.currentOrder.updateable_by_cash_point) {
                    return _this.updateOrder(_this.getReceiptOrderNumber(), order);
                }
                else {
                    return Promise.reject('Current order is not updateable');
                }
            });
        }
        else {
            return this.createOrder(order);
        }
    };
    /**
     * Create an order on the back-end (go~mus)
     *
     * @param {any} order
     * @returns {Promise<{order: Order}>}
     *
     * @memberOf OrderService
     */
    // TODO @doug add order to receipt
    OrderService.prototype.createOrder = function (order) {
        return this.http
            .post(this.config.get('apiBaseUrl') + '/orders', order, {
            withCredentials: true,
        })
            .toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    };
    OrderService.prototype.updateOrder = function (id, order) {
        if (order.items) {
            order.total = this.calcTotal(order);
        }
        return this.http
            .put(this.config.get('apiBaseUrl') + '/orders/' + id, order, {
            withCredentials: true,
        })
            .toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    };
    OrderService.prototype.calcTotal = function (order) {
        var total = this.currentOrder.total_price_cents + order.total;
        return total;
    };
    OrderService.prototype.deleteItem = function (orderId, item) {
        return this.http
            .delete(this.config.get('apiBaseUrl') + '/orders/' + orderId + '/order_items/' + item.id, { withCredentials: true, responseType: 'text' })
            .toPromise()
            .then()
            .catch(this.handleError);
    };
    OrderService.prototype.splitOrder = function (orderId, itemIds) {
        return this.http
            .put(this.config.get('apiBaseUrl') + '/orders/' + orderId + '/split/', { order_item_ids: itemIds }, {
            withCredentials: true,
        })
            .toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    };
    OrderService.prototype.addCashPointReferenceNumber = function (order) {
        if (typeof korona_plugin_api !== 'undefined' && korona_plugin_api.request) {
            order.cash_point_reference_number = korona_plugin_api.request.receipt.number;
        }
        return order;
    };
    /**
     * Get an order from the back-end (go~mus) using a given barcode
     *
     * @param {string} barcode
     * @returns {Promise<{order: Order}>}
     *
     * @memberOf OrderServices
     */
    OrderService.prototype.findOrder = function (barcode, config) {
        if (this.validateBarcode(barcode)) {
            return this.http
                .get(this.config.get('apiBaseUrl') + '/orders/barcode/' + this.cleanBarcode(barcode), {
                withCredentials: true,
            })
                .toPromise()
                .then(this.extractData)
                .catch(this.handleError);
        }
        else if (this.validatOrderId(barcode)) {
            return new Promise(function (resolve, reject) {
                resolve({ order: { id: barcode } });
            });
        }
        else if (barcode.length >= 3) {
            return this.http
                .get(this.config.get('apiBaseUrl') + '/orders', {
                withCredentials: true,
                params: {
                    q: barcode,
                    per_page: config.itemsPerPage.toString(),
                    page: config.currentPage.toString(),
                },
            })
                .toPromise()
                .then(this.extractData)
                .catch(this.handleError);
        }
        else {
            return new Promise(function (resolve, reject) {
                reject({ error: 'Not a valid barcode or order id.' });
            });
        }
    };
    OrderService.prototype.findSales = function (orderId) {
        var sales = [];
        var regex = new RegExp(orderId);
        if (typeof korona_plugin_api !== 'undefined' && korona_plugin_api.request && korona_plugin_api.request.receipt.sales) {
            korona_plugin_api.request.receipt.sales.forEach(function (sale) {
                if (sale.customData && regex.exec(sale.customData)) {
                    sales.push(sale.modifier);
                }
            });
        }
        return sales;
    };
    OrderService.prototype.removeSales = function (sales) {
        if (typeof korona_plugin_api !== 'undefined') {
            sales.forEach(function (sale) {
                korona_plugin_api.response.removeReceiptItem(sale);
            });
        }
    };
    OrderService.prototype.validateBarcode = function (barcode) {
        if (this.barcoderService.validate(barcode)) {
            return true;
        }
        else {
            return false;
        }
    };
    OrderService.prototype.cleanBarcode = function (barcode) {
        // don't replace for new barcodes
        var regex = /2\d\d[a-zA-Z0-9-_]{12,}/;
        if (regex.exec(barcode)) {
            return barcode;
        }
        return barcode.replace(/[^\d]/, '-');
    };
    OrderService.prototype.validatOrderId = function (orderId) {
        var validChars = /^\d+$/;
        if (validChars.exec(orderId)) {
            return true;
        }
        else {
            return false;
        }
    };
    OrderService.prototype.addTicketSale = function (ticket) {
        var _this = this;
        if (ticket.attributes.canceled_at) {
            return;
        }
        var serialNumbers = map(ticket.attributes.barcodes, function (b) { return b.barcode; });
        if (!ticket.attributes.is_mantle && serialNumbers.length < ticket.attributes.quantity) {
            serialNumbers = serialNumbers.concat(Array(ticket.attributes.quantity - serialNumbers.length).fill('na'));
        }
        ticket.attributes.accounting_article_usings.forEach(function (accountingArticle, index) {
            if (!accountingArticle.quantity || accountingArticle.is_sub) {
                return;
            }
            var sale = {
                serialNumbers: serialNumbers,
                recognitionCode: accountingArticle.number,
                quantity: accountingArticle.quantity,
                price: _this.currentOrder.paid ? 0 : accountingArticle.original_price_cents / 100,
                discount: accountingArticle.discount_price_cents / 100,
                customData: JSON.stringify({
                    order_id: _this.currentOrder.id,
                    ticket_id: ticket.attributes.id,
                }),
                customReferences: [
                    { type: 'order_item_id', ref: ticket.id.toString() },
                ],
                ticketDefinition: {
                    mergedTicketPrintout: !!ticket.attributes.is_collective,
                },
            };
            if (index === 0) {
                Object.assign(sale, {
                    infoTexts: _this.buildInfoTexts(_this.currentOrder, ticket, accountingArticle),
                });
            }
            korona_plugin_api.response.addSale(sale);
        });
        if (ticket.attributes.is_mantle && !ticket.attributes.is_offset_mantle) {
            ticket.attributes.sub_ticket_sales.forEach(function (subTicketAttrs) {
                var subTicket = __assign({}, ticket, { attributes: subTicketAttrs });
                _this.addTicketSale(subTicket);
            });
        }
    };
    OrderService.prototype.checkOrderIsOrderReceipt = function (orderId) {
        return this.receiptNumberExists() && orderId.toString() === this.getReceiptOrderNumber();
    };
    OrderService.prototype.buildInfoTexts = function (order, item, accountingArticle) {
        var data = { order: order, item: item, accountingArticle: accountingArticle };
        return this.config.get("infoTexts" + item.type).reduce(function (acc, key) {
            var value = key.split('.').reduce(function (a, b) { return (a ? a[b] : null); }, data);
            if (value) {
                acc.push(value);
            }
            return acc;
        }, []);
    };
    OrderService.prototype.addEventSale = function (event) {
        var _this = this;
        var serialNumbers = map(event.attributes.barcodes, function (b) { return b.barcode; });
        var participantTotal = event.attributes.quantity || sum(map(event.attributes.quantities, function (price) { return price.quantity; }));
        if (serialNumbers.length < participantTotal && event.attributes.barcode) {
            serialNumbers = Array(participantTotal).fill(event.attributes.barcode.barcode);
        }
        event.attributes.accounting_article_usings.forEach(function (accountingArticle, index) {
            if (!accountingArticle.quantity || accountingArticle.is_sub) {
                return;
            }
            var customReferences = [{ type: 'order_item_id', ref: event.id.toString() }];
            if (accountingArticle.scale_price_id) {
                customReferences.push({ type: 'scale_price_id', ref: accountingArticle.scale_price_id.toString() });
            }
            if (accountingArticle.seat_id) {
                customReferences.push({ type: 'seat_id', ref: accountingArticle.seat_id.toString() });
            }
            var articleSerialNumbers = serialNumbers.splice(0, accountingArticle.quantity);
            var sale = {
                serialNumbers: articleSerialNumbers,
                recognitionCode: accountingArticle.number,
                quantity: accountingArticle.quantity,
                price: _this.currentOrder.paid ? 0 : accountingArticle.original_price_cents / 100,
                discount: accountingArticle.discount_price_cents / 100,
                infoTexts: _this.buildInfoTexts(_this.currentOrder, event, accountingArticle),
                customData: JSON.stringify({
                    order_id: _this.currentOrder.id,
                    event_id: event.attributes.id,
                }),
                customReferences: customReferences,
            };
            if (index === 0) {
                Object.assign(sale, {
                    description: event.attributes.title,
                });
            }
            korona_plugin_api.response.addSale(sale);
        });
    };
    OrderService.prototype.addTourSale = function (tour) {
        var _this = this;
        var serialNumbers = null;
        if (tour.attributes.barcode) {
            serialNumbers = [tour.attributes.barcode.barcode];
        }
        tour.attributes.accounting_article_usings.forEach(function (accountingArticle, index) {
            if (!accountingArticle.quantity || accountingArticle.is_sub) {
                return;
            }
            var sale = {
                recognitionCode: accountingArticle.number,
                quantity: accountingArticle.quantity,
                price: _this.currentOrder.paid ? 0 : accountingArticle.original_price_cents / 100,
                discount: accountingArticle.discount_price_cents / 100,
                customData: JSON.stringify({
                    order_id: _this.currentOrder.id,
                    tour_id: tour.attributes.id,
                }),
                customReferences: [{ type: 'order_item_id', ref: tour.id.toString() }],
                notRemovable: true,
            };
            if (index === 0) {
                Object.assign(sale, {
                    serialNumbers: serialNumbers,
                    infoTexts: _this.buildInfoTexts(_this.currentOrder, tour, accountingArticle),
                    description: tour.attributes.title,
                });
            }
            korona_plugin_api.response.addSale(sale);
        });
    };
    OrderService.prototype.addToReceipt = function () {
        var _this = this;
        if (this.currentOrder.invoice) {
            return;
        }
        var sales = this.findSales(this.currentOrder.id.toString());
        this.removeSales(sales);
        this.currentOrder.items.forEach(function (item) {
            if (!item.attributes.canceled_at || (item.attributes.canceled_at && item.price_cents > 0)) {
                if (item.type === 'Ticket') {
                    _this.addTicketSale(item);
                }
                else if (item.type === 'Event') {
                    _this.addEventSale(item);
                }
                else if (item.type === 'Tour') {
                    _this.addTourSale(item);
                }
            }
        });
    };
    OrderService.prototype.setReceiptData = function () {
        if (this.currentOrder && this.currentOrder.customer) {
            if (this.currentOrder.korona_customer_number) {
                korona_plugin_api.response.setReceiptCustomer({
                    number: this.currentOrder.korona_customer_number,
                    firstName: this.currentOrder.customer.name,
                    lastName: this.currentOrder.customer.surname,
                    phone: this.currentOrder.customer.tel,
                });
            }
            else {
                this.rollbar.warn("order has no korona_customer_number", this.currentOrder);
            }
        }
        if (this.currentOrder.billing_address_id) {
            korona_plugin_api.response.setReceiptCustomData(this.currentOrder.billing_address_id.toString());
        }
        var orderNumber = (korona_plugin_api.request && korona_plugin_api.request.receipt.orderNumber) || this.currentOrder.id.toString();
        korona_plugin_api.response.setReceiptOrderNumber(orderNumber);
    };
    OrderService.prototype.finish = function () {
        var _this = this;
        if (this.canAddToReceipt(this.currentOrder.id)) {
            this.updateOrder(this.currentOrder.id, this.addCashPointReferenceNumber({})).then(function () {
                _this.addToReceipt();
                _this.setReceiptData();
                korona_plugin_api.backToKorona();
            });
        }
    };
    /**
     * Initialises an order with the order class
     *
     * @private
     * @param {any} order
     * @returns {Order}
     *
     * @memberOf OrderService
     */
    OrderService.prototype.initOrder = function (order) {
        return new Order(order, this.config.config);
    };
    OrderService.prototype.initOrders = function (orders) {
        var _this = this;
        var newOrders = [];
        orders.forEach(function (order) {
            newOrders.push(_this.initOrder(order));
        });
        return newOrders;
    };
    return OrderService;
}());
export { OrderService };
