import 'rxjs/add/operator/toPromise';

import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, Optional } from '@angular/core';
import * as isArray from 'lodash/isArray';
import * as map from 'lodash/map';
import * as sum from 'lodash/sum';
import * as values from 'lodash/values';
import { PaginationInstance } from 'ngx-pagination';
import * as Rollbar from 'rollbar';

import { AccountingArticle, EventItem, Order, TicketItem, TourItem } from '../../orders/shared';
import { BarcoderService } from '../../shared/services/barcoder.service';
import { Error } from '../components/error';
import { Meta } from '../gomus';
import { Korona } from '../korona';
import { ConfigService } from './config.service';
import { RollbarService } from './error-handler.service';

declare var korona_plugin_api: Korona;

/**
 * Handles getting, finding, and creating of orders
 *
 * @export
 * @class OrderService
 */
@Injectable()
export class OrderService {
  currentOrder: Order;
  orderInProgress: boolean;
  rollbar: Rollbar;

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    injector: Injector,
    @Optional() private barcoderService: BarcoderService
  ) {
    this.rollbar = injector.get(RollbarService);
  }

  /**
   * Gets an array of orders from the back-end (go~mus)
   *
   * @returns {Promise<{orders: Order[], meta: Meta}>}
   *
   * @memberOf OrderService
   */
  getOrders(config: PaginationInstance): Promise<{ orders: Order[]; meta: Meta }> {
    return this.http
      .get(this.config.get('apiBaseUrl') + '/orders', {
        withCredentials: true,
        params: { per_page: config.itemsPerPage.toString(), page: config.currentPage.toString() },
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Gets an order from the back-end (go~mus)
   *
   * @param {string} order_id
   * @returns {Promise<{order: Order}>}
   *
   * @memberOf OrderService
   */
  getOrder(order_id): Promise<{ order: Order }> {
    return this.http
      .get(this.config.get('apiBaseUrl') + '/orders/' + order_id, {
        withCredentials: true,
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  receiptNumberExists(): Boolean {
    const orderId = this.getReceiptOrderNumber();
    return !!orderId.length;
  }

  existingOrderUpdateable(): Promise<boolean> {
    if (this.receiptNumberExists()) {
      return this.getOrder(this.getReceiptOrderNumber()).then(res => {
        return res.order.updateable_by_cash_point;
      });
    } else {
      return Promise.resolve(true);
    }
  }

  getReceiptOrderNumber(): string {
    const orderNumberAction = typeof korona_plugin_api !== 'undefined' &&
      korona_plugin_api.response.actions.find(action => action.type === 'setReceiptOrderNumberAction');
    return (typeof korona_plugin_api !== 'undefined' && korona_plugin_api.request &&
      korona_plugin_api.request.receipt.orderNumber) || orderNumberAction && orderNumberAction.orderNumber || '';
  }

  canAddToReceipt(orderId: string | number): boolean {
    return !this.receiptNumberExists() || this.getReceiptOrderNumber() === orderId.toString() ? true : false;
  }

  order(order): Promise<{ order: Order }> {
    this.orderInProgress = true;
    if (this.receiptNumberExists()) {
      return this.getOrder(this.getReceiptOrderNumber()).then(resp => {
        this.currentOrder = resp.order;
        if (this.currentOrder.updateable_by_cash_point) {
          return this.updateOrder(this.getReceiptOrderNumber(), order);
        } else {
          return Promise.reject('Current order is not updateable');
        }
      });
    } else {
      return this.createOrder(order);
    }
  }

  /**
   * Create an order on the back-end (go~mus)
   *
   * @param {any} order
   * @returns {Promise<{order: Order}>}
   *
   * @memberOf OrderService
   */
  // TODO @doug add order to receipt
  createOrder(order): Promise<{ order: Order }> {
    return this.http
      .post(this.config.get('apiBaseUrl') + '/orders', order, {
        withCredentials: true,
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  updateOrder(id, order): Promise<{ order: Order }> {
    if (order.items) {
      order.total = this.calcTotal(order);
    }
    return this.http
      .put(this.config.get('apiBaseUrl') + '/orders/' + id, order, {
        withCredentials: true,
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  calcTotal(order) {
    const total = this.currentOrder.total_price_cents + order.total;
    return total;
  }

  deleteItem(orderId, item): Promise<{}> {
    return this.http
      .delete(this.config.get('apiBaseUrl') + '/orders/' + orderId + '/order_items/' + item.id, { withCredentials: true, responseType: 'text' })
      .toPromise()
      .then()
      .catch(this.handleError);
  }

  splitOrder(orderId, itemIds): Promise<{}> {
    return this.http
      .put(
        this.config.get('apiBaseUrl') + '/orders/' + orderId + '/split/',
        { order_item_ids: itemIds },
        {
          withCredentials: true,
        }
      )
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  addCashPointReferenceNumber(order) {
    if (typeof korona_plugin_api !== 'undefined' && korona_plugin_api.request) {
      order.cash_point_reference_number = korona_plugin_api.request.receipt.number;
    }
    return order;
  }

  /**
   * Get an order from the back-end (go~mus) using a given barcode
   *
   * @param {string} barcode
   * @returns {Promise<{order: Order}>}
   *
   * @memberOf OrderServices
   */
  findOrder(barcode: string, config: PaginationInstance): Promise<{ order: any }> {
    if (this.validateBarcode(barcode)) {
      return this.http
        .get(this.config.get('apiBaseUrl') + '/orders/barcode/' + this.cleanBarcode(barcode), {
          withCredentials: true,
        })
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    } else if (this.validatOrderId(barcode)) {
      return new Promise((resolve, reject) => {
        resolve({ order: { id: barcode } });
      });
    } else if (barcode.length >= 3) {
      return this.http
        .get(this.config.get('apiBaseUrl') + '/orders', {
          withCredentials: true,
          params: {
            q: barcode,
            per_page: config.itemsPerPage.toString(),
            page: config.currentPage.toString(),
          },
        })
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    } else {
      return new Promise((resolve, reject) => {
        reject({ error: 'Not a valid barcode or order id.' });
      });
    }
  }

  findSales(orderId: string) {
    const sales = [];
    const regex = new RegExp(orderId);
    if (typeof korona_plugin_api !== 'undefined' && korona_plugin_api.request && korona_plugin_api.request.receipt.sales) {
      korona_plugin_api.request.receipt.sales.forEach(sale => {
        if (sale.customData && regex.exec(sale.customData)) {
          sales.push(sale.modifier);
        }
      });
    }
    return sales;
  }

  removeSales(sales) {
    if (typeof korona_plugin_api !== 'undefined') {
      sales.forEach(sale => {
        korona_plugin_api.response.removeReceiptItem(sale);
      });
    }
  }

  validateBarcode(barcode): boolean {
    if (this.barcoderService.validate(barcode)) {
      return true;
    } else {
      return false;
    }
  }

  cleanBarcode(barcode): boolean {
    // don't replace for new barcodes
    const regex = /2\d\d[a-zA-Z0-9-_]{12,}/;
    if (regex.exec(barcode)) {
      return barcode;
    }
    return barcode.replace(/[^\d]/, '-');
  }

  validatOrderId(orderId): boolean {
    const validChars = /^\d+$/;
    if (validChars.exec(orderId)) {
      return true;
    } else {
      return false;
    }
  }

  addTicketSale(ticket: TicketItem): void {
    if (ticket.attributes.canceled_at) { return; }
    let serialNumbers = map(ticket.attributes.barcodes, b => b.barcode);
    if (!ticket.attributes.is_mantle && serialNumbers.length < ticket.attributes.quantity) {
      serialNumbers = [...serialNumbers, ...Array(ticket.attributes.quantity - serialNumbers.length).fill('na')];
    }
    ticket.attributes.accounting_article_usings.forEach((accountingArticle, index) => {
      if (!accountingArticle.quantity || accountingArticle.is_sub) { return; }
      const sale = {
        serialNumbers: serialNumbers,
        recognitionCode: accountingArticle.number,
        quantity: accountingArticle.quantity,
        price: this.currentOrder.paid ? 0 : accountingArticle.original_price_cents / 100,
        discount: accountingArticle.discount_price_cents / 100,
        customData: JSON.stringify({
          order_id: this.currentOrder.id,
          ticket_id: ticket.attributes.id,
        }),
        customReferences: [
          { type: 'order_item_id', ref: ticket.id.toString() },
        ],
        ticketDefinition: {
          mergedTicketPrintout: !!ticket.attributes.is_collective,
        },
      };
      if (index === 0) {
        Object.assign(sale, {
          infoTexts: this.buildInfoTexts(this.currentOrder, ticket, accountingArticle),
        });
      }
      korona_plugin_api.response.addSale(sale);
    });
    if (ticket.attributes.is_mantle && !ticket.attributes.is_offset_mantle) {
      ticket.attributes.sub_ticket_sales.forEach(subTicketAttrs => {
        const subTicket = {...ticket, attributes: subTicketAttrs};
        this.addTicketSale(subTicket);
      });
    }
  }

  checkOrderIsOrderReceipt(orderId) {
    return this.receiptNumberExists() && orderId.toString() === this.getReceiptOrderNumber();
  }

  buildInfoTexts(order: Order, item: EventItem | TourItem | TicketItem, accountingArticle: AccountingArticle): string[] {
    const data = { order, item, accountingArticle };
    return this.config.get(`infoTexts${item.type}`).reduce((acc, key) => {
      const value = key.split('.').reduce((a, b) => (a ? a[b] : null), data);
      if (value) {
        acc.push(value);
      }
      return acc;
    }, []);
  }

  addEventSale(event: EventItem): void {
    let serialNumbers = map(event.attributes.barcodes, b => b.barcode);
    const participantTotal = event.attributes.quantity || sum(map(event.attributes.quantities, price => price.quantity));
    if (serialNumbers.length < participantTotal && event.attributes.barcode) {
      serialNumbers = Array(participantTotal).fill(event.attributes.barcode.barcode);
    }
    event.attributes.accounting_article_usings.forEach((accountingArticle, index) => {
      if (!accountingArticle.quantity || accountingArticle.is_sub) { return; }
      let customReferences = [{ type: 'order_item_id', ref: event.id.toString() }];
      if (accountingArticle.scale_price_id) {
        customReferences.push({ type: 'scale_price_id', ref: accountingArticle.scale_price_id.toString() })
      }
      if (accountingArticle.seat_id) {
        customReferences.push({ type: 'seat_id', ref: accountingArticle.seat_id.toString() })
      }

      const articleSerialNumbers = serialNumbers.splice(0, accountingArticle.quantity);
      const sale = {
        serialNumbers: articleSerialNumbers,
        recognitionCode: accountingArticle.number,
        quantity: accountingArticle.quantity,
        price: this.currentOrder.paid ? 0 : accountingArticle.original_price_cents / 100,
        discount: accountingArticle.discount_price_cents / 100,
        infoTexts: this.buildInfoTexts(this.currentOrder, event, accountingArticle),
        customData: JSON.stringify({
          order_id: this.currentOrder.id,
          event_id: event.attributes.id,
        }),
        customReferences: customReferences,
      };
      if (index === 0) {
        Object.assign(sale, {
          description: event.attributes.title,
        });
      }
      korona_plugin_api.response.addSale(sale);
    });
  }

  addTourSale(tour: TourItem): void {
    let serialNumbers = null;
    if (tour.attributes.barcode) {
      serialNumbers = [tour.attributes.barcode.barcode];
    }
    tour.attributes.accounting_article_usings.forEach((accountingArticle, index) => {
      if (!accountingArticle.quantity || accountingArticle.is_sub) { return; }
      const sale = {
        recognitionCode: accountingArticle.number,
        quantity: accountingArticle.quantity,
        price: this.currentOrder.paid ? 0 : accountingArticle.original_price_cents / 100,
        discount: accountingArticle.discount_price_cents / 100,
        customData: JSON.stringify({
          order_id: this.currentOrder.id,
          tour_id: tour.attributes.id,
        }),
        customReferences: [{ type: 'order_item_id', ref: tour.id.toString() }],
        notRemovable: true,
      };
      if (index === 0) {
        Object.assign(sale, {
          serialNumbers: serialNumbers,
          infoTexts: this.buildInfoTexts(this.currentOrder, tour, accountingArticle),
          description: tour.attributes.title,
        });
      }
      korona_plugin_api.response.addSale(sale);
    });
  }

  addToReceipt() {
    if (this.currentOrder.invoice) {
      return;
    }
    const sales = this.findSales(this.currentOrder.id.toString());
    this.removeSales(sales);
    this.currentOrder.items.forEach(item => {
      if (!item.attributes.canceled_at || (item.attributes.canceled_at && item.price_cents > 0)) {
        if (item.type === 'Ticket') {
          this.addTicketSale(item);
        } else if (item.type === 'Event') {
          this.addEventSale(item);
        } else if (item.type === 'Tour') {
          this.addTourSale(item);
        }
      }
    });
  }

  setReceiptData() {
    if (this.currentOrder && this.currentOrder.customer) {
      if (this.currentOrder.korona_customer_number) {
        korona_plugin_api.response.setReceiptCustomer({
          number: this.currentOrder.korona_customer_number,
          firstName: this.currentOrder.customer.name,
          lastName: this.currentOrder.customer.surname,
          phone: this.currentOrder.customer.tel,
        });
      } else {
        this.rollbar.warn("order has no korona_customer_number", this.currentOrder);
      }
    }
    if (this.currentOrder.billing_address_id) {
      korona_plugin_api.response.setReceiptCustomData(this.currentOrder.billing_address_id.toString());
    }
    const orderNumber = (korona_plugin_api.request && korona_plugin_api.request.receipt.orderNumber) || this.currentOrder.id.toString();
    korona_plugin_api.response.setReceiptOrderNumber(orderNumber);
  }

  finish() {
    if (this.canAddToReceipt(this.currentOrder.id)) {
      this.updateOrder(this.currentOrder.id, this.addCashPointReferenceNumber({})).then(() => {
        this.addToReceipt();

        this.setReceiptData();

        korona_plugin_api.backToKorona();
      });
    }
  }

  /**
   * Initialises an order with the order class
   *
   * @private
   * @param {any} order
   * @returns {Order}
   *
   * @memberOf OrderService
   */
  private initOrder(order): Order {
    return new Order(order, this.config.config);
  }

  private initOrders(orders): Order[] {
    const newOrders = [];
    orders.forEach(order => {
      newOrders.push(this.initOrder(order));
    });
    return newOrders;
  }

  private handleError = (error: any): Promise<Error> => {
    const posNumber = korona_plugin_api && korona_plugin_api.request && korona_plugin_api.request.pos.number;
    this.rollbar.warn({ ...error, posNumber });
    if (error.body || error._body) {
      error.body = error.json();
      if (error.body.errors && !isArray(error.body.errors)) {
        error.body.error = values(error.body['errors']).join('\n');
        error.body.errors = null;
      }
    }
    if (error.error && error.error.errors) {
      if (error.error.errors && !isArray(error.error.errors)) {
        error.body = { error: values(error.error.errors).join('\n') };
      }
    } else if (error.error) {
      error.body = error.error;
    }
    this.orderInProgress = false;
    return Promise.reject(error);
  };

  private extractData = res => {
    if (res.order) {
      res.order = this.initOrder(res.order);
      this.currentOrder = res.order;
    } else if (res.orders) {
      res.orders = this.initOrders(res.orders);
    }
    this.orderInProgress = false;
    return res || {};
  };
}
